import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import React, { Suspense, useEffect, useLayoutEffect, useRef } from 'react';
import { BrowserView, MobileView, isBrowser, browserName, isMobile, isIOS, isMobileOnly } from 'react-device-detect';
import { AuthContextProvider } from './services/firebase' //useAuthState
import ProtectedRoutes from './services/protected-routes'
import PublicRoutes from './services/public-routes'
import CustomNavBar from './layouts/navbar/Navbar'
import Sidebar from './layouts/sidebar/Sidebar'
import './App.scss'
import { UserContextProvider } from './services/User';
import { StaticContextProvider } from './services/StaticContext';
import { LoaderProvider } from './services/LoaderProvider';
import PrivacyPolicy from './components/pdf-modal/privacy-policy/PrivacyPolicy';
import TermsAndConditions from './components/pdf-modal/terms-conditions/TermsConditions';
import Blog from './pages/blog/Blog';

const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTo(0, -1);
    }, 100);
  }, [location.pathname]);
  return children;
}

const Home = React.lazy(() => import('./pages/home/Home'));
const SignUp = React.lazy(() => import('./pages/signup/SignUp'));
const Login = React.lazy(() => import('./pages/login/Login'));
const Landing = React.lazy(() => import('./pages/landing/Landing'));
const AllRoutines = React.lazy(() => import('./pages/routines/AllRoutines/AllRoutines'));
const Library = React.lazy(() => import('./pages/library/Library'));
const MainExercises = React.lazy(() => import('./pages/exercises/MainExercises/MainExercises'));
const AllExercises = React.lazy(() => import('./pages/exercises/AllExercises/AllExercises'));
const PerformExercise = React.lazy(() => import('./pages/exercises/PerformExercise/PerformExercise'));
const ExerciseSummary = React.lazy(() => import('./pages/exercises/ExerciseSummary/ExerciseSummary'));
const MuscleGroups = React.lazy(() => import('./pages/muscle-group/MuscleGroups'));
const RoutineSummary = React.lazy(() => import('./pages/routines/RoutineSummary/RoutineSummary'));
const CreateRoutine = React.lazy(() => import('./pages/routines/CreateRoutine/CreateRoutine'));
const ProgramSummary = React.lazy(() => import('./pages/programmes/ProgramSummary/ProgramSummary'));
const PerformRoutine = React.lazy(() => import('./pages/routines/PerformRoutine/PerformRoutine'));
const Body = React.lazy(() => import('./pages/body/body'));
const You = React.lazy(() => import('./pages/you/you'));
const Charts = React.lazy(() => import('./pages/charts/Charts'));
const Master = React.lazy(() => import('./layouts/master/master'));

function App() {
  return (
    <AuthContextProvider>
      <LoaderProvider>
        <UserContextProvider>
          <StaticContextProvider>
            <Router>
              <Wrapper>
                <div className="main-content-container">
                  <CustomNavBar />

                  <Master>
                    <Sidebar />
                    <div className={"main-content" + (isMobile ? ' mobile' : '') + (isIOS ? ' iOS' : '')}>
                      <Routes>
                        <Route path="/" element={<ProtectedRoutes />}>
                        <Route index element={
                            <Suspense fallback={<div></div>}>
                              <Landing />
                            </Suspense>
                          } />

                          <Route path="home" element={
                            <Suspense fallback={<div></div>}>
                              <Home />
                            </Suspense>
                          } />
                          <Route path="exercises" element={
                            <Suspense fallback={<div></div>}>
                              <MainExercises />
                            </Suspense>
                          } />
                          <Route path="exercises/allexercises" element={
                            <Suspense fallback={<div></div>}>
                              <AllExercises />
                            </Suspense>
                          } />
                          <Route path="exercises/perform-exercise/:exerciseId" element={
                            <Suspense fallback={<div></div>}>
                              <PerformExercise />
                            </Suspense>
                          } />
                          <Route path="exercises/exercise-summary/:exerciseId" element={
                            <Suspense fallback={<div></div>}>
                              <ExerciseSummary />
                            </Suspense>
                          } />
                          <Route path="muscle-groups/:muscleGroupId" element={
                            <Suspense fallback={<div></div>}>
                              <MuscleGroups />
                            </Suspense>
                          } />
                          <Route path="exercises/main-exercises" element={
                            <Suspense fallback={<div></div>}>
                              <MainExercises />
                            </Suspense>
                          } />
                          <Route path="routines/allroutines" element={
                            <Suspense fallback={<div></div>}>
                              <AllRoutines />
                            </Suspense>
                          } />
                          <Route path="routines/create-routine" element={
                            <Suspense fallback={<div></div>}>
                              <CreateRoutine />
                            </Suspense>
                          } />
                          <Route path="routines/create-routine/:routineId" element={
                            <Suspense fallback={<div></div>}>
                              <CreateRoutine />
                            </Suspense>
                          } />
                          <Route path="routines/routine-summary/:routineId" element={
                            <Suspense fallback={<div></div>}>
                              <RoutineSummary />
                            </Suspense>
                          } />
                          <Route path="routines/routine-summary/:routineId/:isCustom" element={
                            <Suspense fallback={<div></div>}>
                              <RoutineSummary />
                            </Suspense>
                          } />
                          <Route path="routines/routine-summary/:routineId/:isCustom/:from" element={
                            <Suspense fallback={<div></div>}>
                              <RoutineSummary />
                            </Suspense>
                          } />
                          <Route path="routines/perform-routine/:routineId" element={
                            <Suspense fallback={<div></div>}>
                              <PerformRoutine />
                            </Suspense>
                          } />
                          <Route path="routines/perform-routine/:routineId/:from" element={
                            <Suspense fallback={<div></div>}>
                              <PerformRoutine />
                            </Suspense>
                          } />
                          <Route path="routines/perform-routine/:routineId/:from/:fromProgram" element={
                            <Suspense fallback={<div></div>}>
                              <PerformRoutine />
                            </Suspense>
                          } />
                          <Route path="programs/program-summary/:programId" element={
                            <Suspense fallback={<div></div>}>
                              <ProgramSummary />
                            </Suspense>
                          } />
                          <Route path="programs/program-summary/:programId/:from" element={
                            <Suspense fallback={<div></div>}>
                              <ProgramSummary />
                            </Suspense>
                          } />
                          <Route path="library" element={
                            <Suspense fallback={<div></div>}>
                              <Library />
                            </Suspense>
                          } />
                          <Route path="your-body" element={
                            <Suspense fallback={<div></div>}>
                              <Body />
                            </Suspense>
                          } />
                          <Route path="user" element={
                            <Suspense fallback={<div></div>}>
                              <You />
                            </Suspense>
                          } />
                          <Route path="charts" element={
                            <Suspense fallback={<div></div>}>
                              <Charts />
                            </Suspense>
                          } />

                          {/* <Route path="/" element={<Navigate to="home" />} /> */}
                        </Route>

                        <Route path="/" element={<PublicRoutes />}>
                          <Route path="signup" element={
                            <Suspense fallback={<div></div>}>
                              <SignUp />
                            </Suspense>
                          } />
                          <Route path="login" element={
                            <Suspense fallback={<div></div>}>
                              <Login />
                            </Suspense>
                          } />

                          </Route>

                        <Route path="landing" element={
                          <Suspense fallback={<div></div>}>
                            <Landing />
                          </Suspense>
                        } />
                        
                        <Route path="privacy-policy" element={
                            <Suspense fallback={<div></div>}>
                              <PrivacyPolicy />
                            </Suspense>
                          } />

                        <Route path="terms-of-use" element={
                            <Suspense fallback={<div></div>}>
                              <TermsAndConditions />
                            </Suspense>
                          } />

                        <Route path="blog" element={
                            <Suspense fallback={<div></div>}>
                              <Blog />
                            </Suspense>
                          } />
                      </Routes>
                    </div>
                  </Master>
                </div>
              </Wrapper>
            </Router>
          </StaticContextProvider>
        </UserContextProvider>
      </LoaderProvider>
    </AuthContextProvider>
  )
}

export default App;
