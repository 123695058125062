import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { useState, useEffect, useContext, createContext } from 'react'
import { getFirestore } from 'firebase/firestore';

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyAvr1aiLEKrmBFGjJ3HA4nXza5cmAiIZ_Y",
  authDomain: "physiofrog-staging.firebaseapp.com",
  projectId: "physiofrog-staging",
  storageBucket: "physiofrog-staging.appspot.com",
  messagingSenderId: "546991911299",
  appId: "1:546991911299:web:f6204c6007ba19a1ff06b6",
  measurementId: "G-0DWZ2TGKFE"
})

export const AuthContext = createContext(null)

export const AuthContextProvider = (props: any) => {
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser as any, setError as any)
    getAuth().onAuthStateChanged(authUser => {
      setLoading(false);
    })
    return () => unsubscribe()
  }, []);

  return <AuthContext.Provider value={{ user, loading, error }} {...props} />
}

export const useAuthState = () => {
  const auth: any = useContext(AuthContext)
  return { ...auth, isLoading: auth.loading, isAuthenticated: auth.user != null }
}

export const db = getFirestore(firebaseApp);
