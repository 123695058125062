//import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import { useAuthState } from './firebase'


const PublicRoutes = (props: any) => {
  const { isAuthenticated, isLoading } = useAuthState()

  if (isAuthenticated && !isLoading) window.localStorage.setItem('currentNav', 'home');

	return isAuthenticated && !isLoading ? <Navigate to="/home"/>: <Outlet/>
}


export default PublicRoutes;