import { createContext, useContext, useEffect, useState } from 'react';
import { db } from './firebase';
import { collection, doc, getDocs, onSnapshot, query } from "firebase/firestore";
import { MuscleGroup } from '../interfaces/muscle-group';
import { ActivityOccupation } from '../interfaces/activity';

export const StaticContext = createContext({});

type StaticDataState = {
  muscleGroups: MuscleGroup[]
  summaryMuscleGroups: MuscleGroup[]
  occupations: ActivityOccupation[]
  activities: ActivityOccupation[]
  additionalActivities: ActivityOccupation[]
}

export const StaticContextProvider = (props: any) => {
  const [staticData, setStaticData] = useState<StaticDataState>()

  useEffect(() => {
    fetchApi();
  }, []);

  async function fetchApi() {
    let activityList: any[] = [];
    let occupationList: any[] = [];
    let muscleGroupList: any[] = [];
    let addMuscleGroupList: any[] = [];
    let additionalActivityList: any[] = [];
    await getDocs(query(collection(db, "activity"))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => activityList.push(doc.data()));
    });

    await getDocs(query(collection(db, "additional-activity"))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => additionalActivityList.push(doc.data()));
    });

    await getDocs(query(collection(db, "occupation"))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => occupationList.push(doc.data() as ActivityOccupation));
    });

    await getDocs(query(collection(db, "muscle-groups"))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => muscleGroupList.push(doc.data()));
    });

    await getDocs(query(collection(db, "additional-muscle-group"))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => addMuscleGroupList.push(doc.data()));
    });

    setStaticData( {...staticData, muscleGroups: muscleGroupList.sort((a, b) => a.order > b.order ? 1 : -1 ),
      occupations: occupationList, activities: activityList.sort((a, b) => a.order > b.order ? 1 : -1 ), additionalActivities: additionalActivityList, summaryMuscleGroups: addMuscleGroupList });
  }

  return <StaticContext.Provider value={{ staticData }} {...props} />
}



export const useStaticState = () => {
  const data: any = useContext(StaticContext)
  return data?.staticData || {} as any
}