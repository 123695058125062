import './blog.scss'
const Blog = () => {

  return (
    <section id="blog">
       <span>Coming Soon...</span>
    </section>
  );
};

export default Blog;
