import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthState } from './firebase';
import { useLoader } from './LoaderProvider';

const ProtectedRoutes = (props: any) => {
  const { isAuthenticated, isLoading } = useAuthState();
  const location = useLocation();

  if ((isAuthenticated || isLoading) || location.pathname === '/') {
    return <Outlet />;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default ProtectedRoutes;
