import React, { useState, useRef, useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useAuthState } from '../../services/firebase';
import './navbar.scss';
import {
  BrowserView,
  MobileView,
  browserName,
  isBrowser,
  isMobile,
  isMobileOnly,
  isTablet,
} from 'react-device-detect';
import { useUserState } from '../../services/User';
import { BarChartFill } from 'react-bootstrap-icons';

let block: boolean;
const CustomNavBar = (props: any) => {
  const { isAuthenticated, isLoading } = useAuthState();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const userState = useUserState();
  const location = useLocation();
  const mobileNavRef = useRef<HTMLDivElement>(null);
  const [navbarTime, setNavbarTime] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      setTimeout(() => {
        if (mobileNavRef.current && !mobileNavRef.current.contains(event.target as Node)) {
          if (!block) setIsNavExpanded(false);
        }
      }, 10);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/home') {
      if (isLoading === false) {
        setTimeout(() => {
          setNavbarTime(true);
        }, 1);
      }
    } else {
      setNavbarTime(true);
    }
  }, [isLoading]);

  function isLandingOrSign() {
    return (
      location.pathname === '/' || location.pathname === '/login' || location.pathname === '/signup'
    );
  }

  function isSign() {
    return location.pathname === '/login' || location.pathname === '/signup';
  }

  function isLogin() {
    return location.pathname === '/login';
  }

  function setCurrentNav(navValue: string) {
    setIsNavExpanded(!isNavExpanded);
    window.localStorage.setItem('currentNav', navValue);
  }

  function getCurrentNav(): string {
    const val = window.localStorage.getItem('currentNav');
    if (val) {
      return val;
    } else {
      return 'home';
    }
  }

  function isWebView() {
    return (
      browserName.toLowerCase().includes('webview') ||
      browserName.toLowerCase().includes('wkwebview') ||
      browserName.toLowerCase().includes('webkit')
    );
  }

  function isActive(page: string) {
    if (location.pathname.includes('perform-routine')) {
      return '';
    }

    if (getCurrentNav() === page) {
      return ' active';
    } else {
      return '';
    }
  }

  function menuClick() {
    block = true;
    setIsNavExpanded(!isNavExpanded);
    setTimeout(() => {
      block = false;
    }, 100);
  }

  return location && (!isWebView() || location.pathname !== '/' || isTablet) ? (
    <div
      id="CustomNavBar"
      className={(isMobile ? 'mobile' : '') + (isNavExpanded ? ' custom-expanded' : '')}
    >
      <Navbar bg="light" expand="sm">
        <div className="nav-container">
          <a href="/">
            <img
              className="nav-logo"
              src="/images/logos/Red_PhysioFrog_Logo_horizontal.svg"
              alt="red physiofrog icon"
            />
          </a>
          <div className="menu-content-container">
            {!isAuthenticated && isLogin() ? (
              <Nav.Link as={Link} to="/signup" className="web-only sub">
                Sign Up
              </Nav.Link>
            ) : null}
            {isLandingOrSign() && (
              <Nav.Link href='https://blog.physiofrog.io/' className="sub" target="_blank">
                Blog
              </Nav.Link>
            )}
            <div className="right-nav-container">
              {isLandingOrSign() && !isSign() && (
                <Nav.Link as={Link} to="/signup" className="main-btn">
                  <span className="navbar-btn">START</span>
                </Nav.Link>
              )}
              {isAuthenticated && location.pathname !== '/' && (
                <div className="menu-toggle" onClick={() => menuClick()}></div>
              )}
            </div>
          </div>
        </div>

        <div
          ref={mobileNavRef}
          className={isNavExpanded ? 'nav-container-mobile expanded' : 'nav-container-mobile'}
        >
          <Nav.Item
            className={'underline' + isActive('home')}
            as={Link}
            to="/home"
            onClick={() => setCurrentNav('home')}
          >
            <img alt="home icon" height={20} src="/images/icons/home.svg" />
            <span>Home</span>
          </Nav.Item>
          <Nav.Item
            className={'underline' + isActive('your-body')}
            as={Link}
            to="/your-body"
            onClick={() => setCurrentNav('your-body')}
          >
            <img alt="body icon" height={20} src="/images/icons/body.svg" />
            <span>Your Body</span>
          </Nav.Item>
          <Nav.Item
            className={'underline' + isActive('main-exercises')}
            as={Link}
            to="/exercises/main-exercises"
            onClick={() => setCurrentNav('main-exercises')}
          >
            <img alt="exercise icon" height={20} src="/images/icons/exercises.svg" />
            <span>Exercises</span>
          </Nav.Item>
          <Nav.Item
            className={'underline' + isActive('library')}
            as={Link}
            to="/library"
            onClick={() => setCurrentNav('library')}
          >
            <img alt="library icon" height={20} src="/images/icons/library.svg" />
            <span>Library</span>
          </Nav.Item>
          <Nav.Item
            className={'underline' + isActive('user')}
            as={Link}
            to="/user"
            onClick={() => setCurrentNav('user')}
          >
            <img alt="user icon" height={20} src="/images/icons/me.svg" />
            <span>Profile</span>
          </Nav.Item>
          {userState?.isAdmin && (
            <Nav.Item
              as={Link}
              to="/charts"
              onClick={() => setCurrentNav('charts')}
              className={'underline charts' + isActive('charts')}
            >
              <img alt="chart icon" height={20} src="/images/icons/chart-simple-solid.svg" />
              <span>Charts</span>
            </Nav.Item>
          )}
        </div>
      </Navbar>
    </div>
  ) : null;
};

export default CustomNavBar;
