import { createContext, useContext, useEffect, useState } from 'react';
import { db, useAuthState } from './firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { User } from '../interfaces/user';

export const UserContext = createContext({});

export const UserContextProvider = (props: any) => {
  //console.log(props);
  const [user, setUser] = useState<User>()
  //const [error, setError] = useState()

  const { user: authUser } = useAuthState();

  useEffect(() => {
    if (!authUser) return;
    const unsubscribe = onSnapshot(doc(db, 'user', authUser.uid), (doc) => {
      const data = doc?.data();
      if (!data) return;
      setUser({...data as User});
    });
    return () => unsubscribe();
  }, [authUser]);

  return <UserContext.Provider value={{ user }} {...props} />
}

export const useUserState = () => {
  const user: any = useContext(UserContext)
  //console.log(user);
  return { ...user.user }
}
//export default UserContext;