import React, { useState, useEffect } from 'react';

const TermsAndConditions = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/terms.htm') // Assuming the file is in the public folder
      .then((response) => response.text())
      .then((data) => setHtmlContent(data))
      .catch((error) => console.error('Error loading HTML file:', error));
  }, []);

  return (
    <div style={{padding: '30px 30px'}} dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default TermsAndConditions;
