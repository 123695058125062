import React, {forwardRef, useImperativeHandle, useState} from 'react';
//import {ActivityIndicator, StyleSheet, View} from 'react-native';
//import {wp} from '../../styles/responsive';
import './Loader.scss';

function Loader(props: any, ref: any) {
  const [loading, setLoading] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      start: () => {
        //document.documentElement.scrollTo(0, -300);
        //setTimeout(() => {
          const loadingCount = loading + 1;
          document.body.style.overflow = 'hidden';
          setLoading(loadingCount);
        //}, 500);
      },
      stop: () => {
        const loadingCount = loading > 0 ? loading - 1 : 0;
        document.body.style.overflow = 'auto';
        setLoading(loadingCount);
      },
      isLoading: () => loading >= 1,
    }),
    [],
  );

  if (!loading) {
    return null;
  }

  return (
    <div style={{top: document.documentElement.scrollTop}} className='loader-container'>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  );
}

/* const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFill,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#11111150',
    zIndex: 999,
    elevation: 999,
  },
}); */

export default forwardRef(Loader);