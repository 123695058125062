import { Nav } from 'react-bootstrap'
import './sidebar2.scss'
import { useAuthState } from '../../services/firebase';
import { Link, useLocation } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { BarChartFill, Facebook } from 'react-bootstrap-icons';
import { useUserState } from '../../services/User';

const Sidebar = (props: any) => {
  const user = useAuthState();
  const userState = useUserState();
  const location = useLocation();

  function setCurrentNav(navValue: string) {
    window.localStorage.setItem('currentNav', navValue);
  }

  function getCurrentNav(): string {
    const val = window.localStorage.getItem('currentNav');
    if (val) {
      return val;
    } else {
      return 'home';
    }
  }

  function isActive(page: string) {
    if (location.pathname.includes('perform-routine')) {
      return '';
    }

    if (getCurrentNav() === page) {
      return ' active';
    } else {
      return '';
    }
  }

  return (user?.user && location.pathname !== '/' && location.pathname !== '/privacy-policy' && location.pathname !== '/terms-of-use' && location.pathname !== '/blog' ) ? (
    <>
      <Nav
        className={"sidebar" + (isMobile ? ' mobile' : '')}
        activeKey="/home"
        onSelect={selectedKey => alert(`selected ${selectedKey}`)}
        style={{paddingTop: "3em"}}
      >
        <div className="sidebar-sticky"></div>
        <img alt="Frog Icon" width={100} className='frog-logo' src="/images/icons/red_physiofrog_logo.svg"/>

        <Nav.Item as={Link} to="/home" onClick={() => setCurrentNav('home')} className={"underline" + isActive('home')}>
          <img alt="home icon" height={20} src="/images/icons/home.svg"/>
          <span>Home</span>
        </Nav.Item>
        <Nav.Item as={Link} to="/your-body" onClick={() => setCurrentNav('your-body')} className={"underline" + isActive('your-body')}>
          <img alt="body icon" height={20} src="/images/icons/body.svg"/>
          <span>Your Body</span>
        </Nav.Item>
        <Nav.Item as={Link} to="/exercises/main-exercises" onClick={() => setCurrentNav('main-exercises')} className={"underline" + isActive('main-exercises')}>
          <img alt="exercise icon" height={20} src="/images/icons/exercises.svg"/>
          <span>Exercises</span>
        </Nav.Item>
        <Nav.Item as={Link} to="/library" onClick={() => setCurrentNav('library')} className={"underline" + isActive('library')}>
          <img alt="library icon" height={20} src="/images/icons/library.svg"/>
          <span>Library</span>
        </Nav.Item>
        <Nav.Item as={Link} to="/user" onClick={() => setCurrentNav('user')} className={"underline" + isActive('user')}>
          <img alt="user icon" height={20} src="/images/icons/me.svg"/>
          <span>Profile</span>
        </Nav.Item>
        {userState?.isAdmin && <Nav.Item as={Link} to="/charts" onClick={() => setCurrentNav('charts')} className={"underline charts" + isActive('charts')}>
           <BarChartFill></BarChartFill>
          <span>Charts</span>
        </Nav.Item>}
      {/*   <Nav.Item>
          <Nav.Link eventKey="disabled" disabled>
            Disabled
          </Nav.Link>
        </Nav.Item> */}
      </Nav>
    </>
  ) : null
}
//const Sidebar = withRouter(Side);
export default Sidebar
